/**
 * For CPS Deposit Listing Ordering
 * (CPS Module)
 */

import { countryCodeEnum } from '@/constants/country'

export const cpsDepositListingIndex = [
  // Country Specific Sample
  // {
  //   id: countryCodeEnum.INDIA,
  //   order: ['T00600_055', 'GT00400'],
  // },
  {
    id: 'global',
    order: ['T00312_020', 'T00600_063', 'T00100_052', 'T00400', 'T00600_055'],
  },
]

<template>
  <PageWrapper :tabActive.sync="selectedTab" :tabs="tabs">
    <PagePanel>
      <component :is="getComponent" :cpsDepositList="cpsDepositList"></component>
    </PagePanel>
    <!-- <div class="browser-wrapper" v-if="currentWidth >= 1280">
      <h3>{{ $t('menu.depositFund') }} ＞＞＞</h3>
      <div class="selection-box">
        <el-tabs :stretch="true" type="card" v-model="selectedTab">
          <el-tab-pane
            v-for="(tab, index) in Object.values(TABS_ITEMS)"
            :key="index"
            :label="tab.title"
            :name="tab.name"
          >
            <component :is="TABS_ITEMS[selectedTab].component" :cpsDepositList="cpsDepositList"></component>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div> -->

    <!-- mobile -->
    <!-- <div class="mobile-wrapper" v-else>
      <h3>{{ $t('menu.depositFund') }} >>></h3>
      <div class="mobile-title">{{ TABS_ITEMS[selectedTab].title }}</div>
      <ul class="mobile-box">
        <li
          v-for="(mobileTab, idx) in Object.values(TABS_ITEMS)"
          :key="idx"
          :class="{ isActive: mobileTab.name === selectedTab }"
          @click="selectedTab = mobileTab.name"
        >
          <img
            :src="
              require(`@/assets/images/deposit/${mobileTab.img}${mobileTab.name === selectedTab ? '_active' : ''}.png`)
            "
            :alt="mobileTab.name"
            :data-testid="mobileTab.name"
          />
        </li>
      </ul>

      <div class="mobile-top">
        <component :is="TABS_ITEMS[selectedTab].component" :cpsDepositList="cpsDepositList" />
      </div>
    </div> -->
  </PageWrapper>
</template>

<script>
import cpsDepositListingMixin from '@/mixins/page/deposit/cpsDepositListing'

export default {
  name: 'SvgDeposit',
  mixins: [cpsDepositListingMixin],
  data() {
    return {
      selectedTab: 'cryptoCurrency',
      tabs: [
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/cryptoCurrency/depositList').component,
          label: this.$t('common.withdrawChannel.CRYPTOCURRENCY'),
          name: `cryptoCurrency`,
        },
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/creditCard/depositList').component,
          label: this.$t('common.withdrawChannel.creditcard'),
          name: `creditCard`,
        },
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/eWallet/depositList').component,
          label: this.$t('common.withdrawChannel.E_WALLET'),
          name: `eWallet`,
        },
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/localTransfer/depositList').component,
          label: this.$t('common.withdrawChannel.LOCAL_TRANSFER'),
          name: `localTransfer`,
        },
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/bankTransfer/depositList').component,
          label: this.$t('common.withdrawChannel.banktransfer'),
          name: `bankTransfer`,
        },
      ],
      cpsDepositList: [],
    }
  },
  computed: {
    getComponent() {
      return this.tabs.find(item => item.name === this.selectedTab).component
    },
  },
  mounted() {
    // get cps deposit channels list
    this.getCPSDepositChannels()
  },
  methods: {},
}
</script>

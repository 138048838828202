import { countryIsoCode } from '@/constants/countryCode'
import { apiGetCpsAvailableChannel } from '@/resource/cps'
import { cpsDepositListingIndex } from '@/constants/cpsDepositListingIndex'

export default {
  data() {
    return {
      currentComponent: () =>
        this.$config.componentLoader(this.regulator, 'deposit/bankTransfer/depositList').component,
      cpsDepositList: [],
    }
  },
  computed: {
    getCountryIsoCode() {
      return countryIsoCode.find(c => c.id === parseInt(this.$store.state.common.countryCode)).code
    },
  },
  methods: {
    getCPSDepositChannels(interalTest = false) {
      let payload = {
        country: this.getCountryIsoCode,
      }

      apiGetCpsAvailableChannel(payload).then(response => {
        let channelData = JSON.parse(response.data.data)

        // Assign cps deposit list
        this.cpsDepositList = this.filterCpsDepositListByRegulator(channelData.channels, interalTest)
      })
    },
    filterCpsDepositListByRegulator(channels, interalTest = false) {
      let finalCpsDepositList = []
      let parentArray = []
      let depositListOrder = []
      let sortedDepositList = []

      if (channels && channels.length > 0) {
        // check for merchant variable regulator & filtering
        channels.map(m => {
          let regulatorArray = []
          let merchantVariable = m.merchant_variable ? JSON.parse(m.merchant_variable) : ''
          m.merchant_variable = merchantVariable ? merchantVariable : ''

          let attachVariables = m.attach_variable ? Object.values(JSON.parse(m.attach_variable)) : ''
          m.attach_variable = attachVariables ? attachVariables : ''

          if (merchantVariable.regulator) {
            regulatorArray = merchantVariable.regulator.split(',')
          }

          if (interalTest) {
            merchantVariable.production = true
          }

          // push to final cps deposit list if regulator is matched & production is true
          if (regulatorArray.includes(this.regulator) && merchantVariable.production) {
            // Exclude T00400 (Crypto) from merging trade_account
            if (m.payment_method == 'T00400') {
              parentArray.push(m)
            } else {
              let paymentIndex = parentArray.findIndex(function(p) {
                return p.payment_method == (merchantVariable.parent ? merchantVariable.parent : m.payment_method)
              })

              // create parent by payment method
              if (paymentIndex < 0) {
                parentArray.push(m)
              }
              finalCpsDepositList.push(m)
            }
          }
        })

        // start grouping
        if (finalCpsDepositList.length > 0) {
          finalCpsDepositList.forEach((p, index) => {
            let paymentIndex = parentArray.findIndex(function(pi) {
              return pi.payment_method == (p.merchant_variable.parent ? p.merchant_variable?.parent : p.payment_method)
            })

            // only group when payment method matches
            if (paymentIndex >= 0 && parentArray[paymentIndex].merchant_variable?.limit) {
              if (parentArray[paymentIndex].merchant_variable?.trade_account != p.merchant_variable?.trade_account) {
                parentArray[paymentIndex].merchant_variable.trade_account =
                  parentArray[paymentIndex].merchant_variable?.trade_account + ',' + p.merchant_variable?.trade_account

                parentArray[paymentIndex].merchant_variable.limit = parentArray[
                  paymentIndex
                ].merchant_variable.limit.concat(p.merchant_variable.limit)
              }
            }
          })
        }

        // sort by order
        parentArray.sort((a, b) => a.merchant_variable.order - b.merchant_variable.order)

        // Sort based on deposit listing index
        cpsDepositListingIndex.forEach(de => {
          if (de.id == this.countryCode) {
            depositListOrder = de.order
          }
        })

        if (depositListOrder.length == 0) {
          cpsDepositListingIndex.forEach(de => {
            if (de.id == 'global') {
              depositListOrder = de.order
            }
          })
        }

        depositListOrder.forEach(de => {
          parentArray.forEach(pa => {
            if (de == pa.payment_method) {
              sortedDepositList.push(pa)
            }
          })
        })

        const remainingPayments = parentArray.filter(v => !depositListOrder.includes(v.payment_method))
        sortedDepositList = sortedDepositList.concat(remainingPayments)
      }

      return sortedDepositList
    },
  },
}
